import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/index.scss"; // 全局样式 css
import Video from "video.js";
import "video.js/dist/video-js.css";
import axios from 'axios'

Vue.config.productionTip = false;
Vue.prototype.$video = Video;
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
